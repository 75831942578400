
.p-inputwrapper-focus,.p-inputnumber-input {
  outline: none !important;
  box-shadow: none !important;
}

.p-inputmask:focus,
.calendarInput:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.p-inputmask,
.calendarInput {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.p-inputtext,
.calendarInput input {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  font-family: "Poppins", "sans-serif" !important;
  color: rgb(249 250 251) !important;
  padding: 0px 0px !important;
}

.calendarInput {
  border-bottom: 1px solid #fff !important;
  background-color: transparent !important;
  padding: 4px 2px !important;
}
