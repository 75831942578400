@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  --webkit-font-smoothing: antialiased;
  --moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../public/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Bold';
  src: url('../public/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


/* Then use it like this */
body {
  font-family: 'Helvetica', sans-serif;
}