.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(4.79332px);
  padding: 10px;
  border-radius: 55px;
  width: 100%;
  position: relative;
  user-select: none;
}

.steps::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background-color: #fff;
  z-index: 9;
}

.steps .item {
  background-color: #fff;
  width: 16vw;
  height: 16vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 15px rgba(0,0,0,.4);
  z-index: 99;
}

.steps .item h5 {
  font-size: 2vw;
  line-height: 1;
  margin: 0;
}

.steps .item span {
  font-size: 1.3vw;
  line-height: 1.2;
  text-align: center;
}

/* colors */
.steps .item.blue {
  background-color: #008DFF;
  color: #ffffff;
}
.steps .item.blue h5 {
  color: #ffffff;
}

.steps .item.bege {
  background-color: #ffffff;
  color: #727272;
}

.steps .item.rosa {
  background-color: #E5007E;
  color: #ffffff;
}

.steps .item.darkblue{
  background-color: #1E71B8;
  color: #ffffff;
}

.steps .item.accent {
  background-color: #33296A;
  color: #ffffff;
}